import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import NavbarAuth from './Navbar_Authenticated';
import { useAuth } from "../Components/AuthContext.js";
import "./ComponentStyling/DailyJournal.css";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import ReactGA from 'react-ga4';
import { Modal, Button } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa'; // Import Font Awesome Icon
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Line, Bar } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, BarElement } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

// Register chart components
Chart.register(LineElement, BarElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, zoomPlugin);

const DailyJournal = () => {
    const { user } = useAuth();
    const [tasks, setTasks] = useState({
        todo: [],
        inProgress: [],
        complete: []
    });
    const [newTask, setNewTask] = useState('');
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [quillEditor, setQuillEditor] = useState(null);
    const [journalContent, setJournalContent] = useState('');
    const [journalEntryId, setJournalEntryId] = useState(null);
    const [editingCriticality, setEditingCriticality] = useState(null);
    const [sortBy, setSortBy] = useState('none'); // Add this state
    const [selectedTask, setSelectedTask] = useState(null); // Add this state
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [taskNotes, setTaskNotes] = useState('');
    const [taskDueDate, setTaskDueDate] = useState('');
    const [modalTaskContent, setModalTaskContent] = useState('');
    const [modalStoryPoints, setModalStoryPoints] = useState('0');
    const [modalCriticality, setModalCriticality] = useState('L');
    const [modalTaskTitle, setModalTaskTitle] = useState('');
    const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
    const [analyticsData, setAnalyticsData] = useState(null); // Add this state

    const getLocalDateString = () => {
        const localDate = new Date();
        localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
        return localDate.toISOString().split('T')[0];
    };

    const [selectedDate, setSelectedDate] = useState(getLocalDateString());

    useEffect(() => {
        if (user) {
            console.log('Fetching tasks for date:', selectedDate);
            fetchTasks();
            fetchJournalEntry();
        }
    }, [selectedDate, user]);

    useEffect(() => {
        if (!quillEditor) {
            const quillConfig = {
                theme: 'snow',
                placeholder: 'Write your journal entry here...',
                formats: [
                    'bold', 'italic', 'underline', 'strike',
                    'align', 'list', 'indent',
                    'header', 'font', 'color', 'background',
                    'script', 'direction'
                ],
                modules: {
                    toolbar: [
                        [{ 'font': [] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'align': [] }],
                        ['clean']
                    ],
                    keyboard: {
                        bindings: {
                            space: {
                                key: 32,
                                handler: function(range, context) {
                                    this.quill.insertText(range.index, ' ');
                                    this.quill.setSelection(range.index + 1);
                                }
                            }
                        }
                    }
                }
            };

            const quillInstance = new Quill('#editor-journal', quillConfig);
            quillInstance.root.style.fontSize = '16px';
            quillInstance.root.style.minHeight = '200px';
            quillInstance.root.style.width = '100%';
            
            quillInstance.on('text-change', () => {
                const content = quillInstance.root.innerHTML;
                setJournalContent(content);
            });

            setQuillEditor(quillInstance);
        }
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                handleSaveJournal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [journalContent, journalEntryId]);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    const handleSaveJournal = async () => {
        if (journalContent.trim() === '') return;

        try {
            if (journalEntryId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/journalEntries/${journalEntryId}`, { content: journalContent });
                console.log('Journal entry saved');
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/journalEntries`, {
                    userId: user.user_id,
                    date: selectedDate,
                    content: journalContent
                });
                setJournalEntryId(response.data._id);
                console.log('New journal entry saved');
            }
        } catch (error) {
            console.error('Error saving journal entry:', error);
        }
    };

    useEffect(() => {
        if (quillEditor) {
            quillEditor.root.innerHTML = '';
            fetchJournalEntry();
        }
    }, [selectedDate]);

    useEffect(() => {
        if (quillEditor && journalContent) {
            const currentContent = quillEditor.root.innerHTML;
            if (currentContent !== journalContent) {
                const range = quillEditor.getSelection();
                quillEditor.clipboard.dangerouslyPasteHTML(journalContent);
                if (range) {
                    quillEditor.setSelection(range);
                }
            }
        }
    }, [quillEditor, journalContent]);

    const fetchTasks = async () => {
        try {
            console.log('Calling fetchTasks');
            const isoDate = new Date(selectedDate).toISOString();
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks`, {
                params: { date: isoDate, userId: user.user_id }
            });
            const fetchedTasks = response.data;
            console.log('Fetched tasks:', fetchedTasks);

            const sortByCriticality = (tasks) => {
                const criticalityOrder = { 'H': 1, 'M': 2, 'L': 3 };
                return tasks.sort((a, b) => (criticalityOrder[a.criticality] || 3) - (criticalityOrder[b.criticality] || 3));
            };

            setTasks({
                todo: sortByCriticality(fetchedTasks.filter(task => task.status === 'todo')),
                inProgress: sortByCriticality(fetchedTasks.filter(task => task.status === 'inProgress')),
                complete: sortByCriticality(fetchedTasks.filter(task => task.status === 'complete'))
            });
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const fetchJournalEntry = async () => {
        try {
            console.log('Fetching journal entry for user:', user.user_id, 'and date:', selectedDate);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/journalEntries`, {
                params: { userId: user.user_id, date: selectedDate }
            });
            console.log('Journal entry response:', response.data);
            if (response.data) {
                setJournalContent(response.data.content);
                setJournalEntryId(response.data._id);
                if (quillEditor) {
                    quillEditor.clipboard.dangerouslyPasteHTML(response.data.content);
                }
            } else {
                setJournalContent('');
                setJournalEntryId(null);
                if (quillEditor) {
                    quillEditor.root.innerHTML = '';
                }
            }
        } catch (error) {
            console.error('Error fetching journal entry:', error);
            setJournalContent('');
            setJournalEntryId(null);
            if (quillEditor) {
                quillEditor.root.innerHTML = '';
            }
        }
    };

    const handleAddTask = async () => {
        if (newTask.trim()) {
            const taskToAdd = {
                content: newTask,
                status: 'todo',
                date: new Date(selectedDate).toISOString(),
                userId: user.user_id
            };
            try {
                console.log('Adding task:', taskToAdd);
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks`, taskToAdd);
                if (response.status === 201) {
                    console.log('Task added successfully:', response.data);
                    setTasks(prev => ({
                        ...prev,
                        todo: [...prev.todo, { ...taskToAdd, _id: response.data._id }]
                    }));
                    setNewTask('');
                }
            } catch (error) {
                console.error('Error adding task:', error);
            }
        }
    };

    const handleDeleteTask = async (taskId, columnId) => {
        try {
            console.log(`Deleting task with ID: ${taskId}`);
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${taskId}`);
            setTasks(prev => ({
                ...prev,
                [columnId]: prev[columnId].filter(task => task._id !== taskId)
            }));
            console.log('Task deleted successfully');
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const { source, destination } = result;

        if (source.droppableId === destination.droppableId) {
            const column = Array.from(tasks[source.droppableId]);
            const [removed] = column.splice(source.index, 1);
            column.splice(destination.index, 0, removed);

            setTasks(prev => ({
                ...prev,
                [source.droppableId]: column
            }));
        } else {
            const sourceColumn = Array.from(tasks[source.droppableId]);
            const destColumn = Array.from(tasks[destination.droppableId]);
            const [removed] = sourceColumn.splice(source.index, 1);
            destColumn.splice(destination.index, 0, removed);

            setTasks(prev => ({
                ...prev,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destColumn
            }));

            try {
                console.log(`Updating task status for task ID: ${removed._id}`);
                await axios.put(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${removed._id}`, {
                    ...removed,
                    status: destination.droppableId
                });
                console.log('Task status updated successfully');
            } catch (error) {
                console.error('Error updating task status:', error);
            }
        }
    };

    const handleDuplicateTasks = async () => {
        try {
            const previousDate = new Date(selectedDate);
            previousDate.setDate(previousDate.getDate() - 1);
            const isoPreviousDate = previousDate.toISOString();
    
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks`, {
                params: { date: isoPreviousDate, userId: user.user_id }
            });
    
            const previousTasks = response.data.filter(task => task.status === 'todo' || task.status === 'inProgress');
            const duplicatedTasks = previousTasks.map(task => ({
                ...task,
                date: new Date(selectedDate).toISOString(),
                _id: undefined
            }));
    
            for (const task of duplicatedTasks) {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks`, task);
            }
    
            fetchTasks();
        } catch (error) {
            console.error('Error duplicating tasks:', error);
        }
    };

    const handlePreviousMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(prevYear => prevYear - 1);
        } else {
            setCurrentMonth(prevMonth => prevMonth - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(prevYear => prevYear + 1);
        } else {
            setCurrentMonth(prevMonth => prevMonth + 1);
        }
    };

    const getDaysInMonth = (month, year) => {
        const date = new Date(year, month, 1);
        const days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    };

    const getMonthYear = (date) => {
        const options = { year: 'numeric', month: 'long' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    // Add this new handler function
    const handleStoryPointsChange = async (taskId, columnId, points) => {
        try {
            const updatedTask = { 
                ...tasks[columnId].find(task => task._id === taskId), 
                storyPoints: points 
            };
            await axios.put(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${taskId}`, updatedTask);
            setTasks(prev => ({
                ...prev,
                [columnId]: prev[columnId].map(task => 
                    task._id === taskId ? {...task, storyPoints: points} : task
                )
            }));
        } catch (error) {
            console.error('Error updating story points:', error);
        }
    };

    // Add new state for editing story points
    const [editingPoints, setEditingPoints] = useState(null);
    const [pointsContent, setPointsContent] = useState('');

    // Replace handleStoryPointsClick with these functions
    const handleStoryPointsEdit = (e, taskId, points) => {
        e.stopPropagation();
        setEditingPoints(taskId);
        setPointsContent(points || '0');
    };

    const handleStoryPointsBlur = async (taskId, columnId) => {
        const points = parseInt(pointsContent);
        if (!isNaN(points)) { // removed the 0-13 restriction
            await handleStoryPointsChange(taskId, columnId, points);
        }
        setEditingPoints(null);
    };

    // Add this new handler function
    const handleCriticalityChange = async (taskId, columnId, criticality) => {
        try {
            const updatedTask = { 
                ...tasks[columnId].find(task => task._id === taskId), 
                criticality 
            };
            await axios.put(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${taskId}`, updatedTask);
            setTasks(prev => ({
                ...prev,
                [columnId]: prev[columnId].map(task => 
                    task._id === taskId ? {...task, criticality} : task
                )
            }));
            setEditingCriticality(null);
        } catch (error) {
            console.error('Error updating criticality:', error);
        }
    };

    const getCriticalityLabel = (criticality) => {
        switch (criticality) {
            case 'L': return 'Low';
            case 'M': return 'Medium';
            case 'H': return 'High';
            default: return 'Low';
        }
    };

    // Add this sorting function
    const getSortedTasks = (taskList) => {
        const sortingFunctions = {
            'none': tasks => [...tasks],
            'criticality': tasks => {
                const order = { 'H': 1, 'M': 2, 'L': 3 };
                return [...tasks].sort((a, b) => (order[a.criticality] || 3) - (order[b.criticality] || 3));
            },
            'storyPoints': tasks => {
                return [...tasks].sort((a, b) => (b.storyPoints || 0) - (a.storyPoints || 0));
            },
            'storyPointsAsc': tasks => {
                return [...tasks].sort((a, b) => (a.storyPoints || 0) - (b.storyPoints || 0));
            }
        };
        return sortingFunctions[sortBy](taskList);
    };

    const getTotalStoryPoints = (tasks) => {
        return tasks.reduce((total, task) => total + (task.storyPoints || 0), 0);
    };

    const openModal = (task) => { // Modify openModal to set selectedTask
        setSelectedTask(task);
        setTaskNotes(task.notes || '');
        setTaskDueDate(task.dueDate || '');
        setModalTaskContent(task.content);
        setModalStoryPoints(task.storyPoints || '0');
        setModalCriticality(task.criticality || 'L');
        setModalTaskTitle(task.content); // or the property holding task text
        setModalIsOpen(true);
    };

    const closeModal = () => { // Modify closeModal to reset selectedTask
        setModalIsOpen(false);
        setSelectedTask(null);
    };

    const handleSaveTaskDetails = async () => {
        if (selectedTask) {
            try {
                const updatedTask = {
                    ...selectedTask,
                    content: modalTaskTitle, // Use modalTaskTitle to update the task’s content
                    storyPoints: parseInt(modalStoryPoints) || 0,
                    criticality: modalCriticality,
                    notes: taskNotes,
                    dueDate: taskDueDate
                };
                
                await axios.put(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${selectedTask._id}`, updatedTask);
                
                setTasks(prev => ({
                    ...prev,
                    [selectedTask.status]: prev[selectedTask.status].map(task => 
                        task._id === selectedTask._id ? updatedTask : task
                    )
                }));
                
                closeModal();
            } catch (error) {
                console.error('Error saving task details:', error);
            }
        }
    };

    const openAnalyticsPane = () => setIsAnalyticsOpen(true);
    const closeAnalyticsPane = () => setIsAnalyticsOpen(false);

    // Fetch analytics data when the Analytics pane is opened
    useEffect(() => {
        console.log(user)
        if (isAnalyticsOpen && user && user.user_id) { // Ensure user and user._id are defined
            fetchJournalAnalytics(user.user_id);
        }
    }, [isAnalyticsOpen, user]);

    // Add the fetchJournalAnalytics function
    const fetchJournalAnalytics = async (userId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/journalAnalytics?user_id=${userId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setAnalyticsData(data);
        } catch (error) {
            console.error('Error fetching analytics data:', error);
        }
    };

    // Add a utility function to format dates
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1); // Add 1 day
        return date.toLocaleDateString('en-US', options);
    };

    // Define data for the stacked bar chart
    const barData = {
        labels: analyticsData ? analyticsData.map(entry => formatDate(entry.date)) : [],
        datasets: [
            {
                label: 'Completed Story Points',
                data: analyticsData ? analyticsData.map(entry => entry.completedPoints) : [],
                backgroundColor: 'rgba(40, 167, 69, 0.6)', // Semi-transparent green
            },
            {
                label: 'Todo Story Points',
                data: analyticsData ? analyticsData.map(entry => entry.todoPoints) : [],
                backgroundColor: 'rgba(255, 206, 86, 0.6)', // Semi-transparent yellow
            },
        ],
    };

    // Define options for the stacked bar chart
    const barOptions = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Story Points Distribution',
                font: {
                    size: 18
                }
            },
            legend: { position: 'top' },
        },
        scales: {
            x: { 
                stacked: true,
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: { 
                stacked: true,
                title: {
                    display: true,
                    text: 'Story Points',
                },
                beginAtZero: true,
            },
        },
    };

    const lineOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Story Points Over Time',
                font: {
                    size: 18
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                    drag: {
                        enabled: true,
                        modifierKey: null, // Remove the modifierKey requirement
                    },
                    threshold: 10, // Add a small threshold to prevent accidental panning
                },
                zoom: {
                    wheel: {
                        enabled: false,
                    },
                    pinch: {
                        enabled: false,
                    },
                    mode: 'x',
                },
            },
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: { 
                title: {
                    display: true,
                    text: 'Date'
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45 // Added rotation for x-axis labels
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Total Points'
                }
            },
        },
    };

    return (
        <>
            <div className="dailyJournal-App">
                <NavbarAuth />
                <main className="dailyJournal-main-content">
                    <div className="dailyJournal-tasks-container">
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                <h1 className="dailyJournal-header">Daily Journal</h1>
                            </div>
                            <div className="dailyJournal-month-year text-center mb-4">
                                <button className="dailyJournal-arrow" onClick={handlePreviousMonth}>&lt;</button>
                                {getMonthYear(new Date(currentYear, currentMonth))}
                                <button className="dailyJournal-arrow" onClick={handleNextMonth}>&gt;</button>
                            </div>
                            <div className="dailyJournal-date-picker">
                                {getDaysInMonth(currentMonth, currentYear).map((day, index) => {
                                    const dayString = day.toISOString().split('T')[0];
                                    return (
                                        <div
                                            key={index}
                                            className={`dailyJournal-day ${dayString === selectedDate ? 'dailyJournal-day-selected' : ''}`}
                                            onClick={() => setSelectedDate(dayString)}
                                        >
                                            {day.getDate()}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="dailyJournal-input">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={newTask}
                                    onChange={(e) => setNewTask(e.target.value)}
                                    placeholder="Enter new task"
                                />
                                <button className="btn btn-primary dailyJournal-addTask" onClick={handleAddTask}>Add Task</button>
                            </div>
                            <div className="dailyJournal-sort-controls">
                                <select 
                                    className="dailyJournal-sort-select"
                                    value={sortBy}
                                    onChange={(e) => setSortBy(e.target.value)}
                                >
                                    <option value="none">Sort by...</option>
                                    <option value="criticality">Criticality (H → L)</option>
                                    <option value="storyPoints">Story Points (High → Low)</option>
                                    <option value="storyPointsAsc">Story Points (Low → High)</option>
                                </select>
                                <button className="btn btn-primary" onClick={handleDuplicateTasks}>
                                    Duplicate Tasks
                                </button>
                                <button onClick={openAnalyticsPane} className="dailyJournal-analytics-button">
                                    Analytics
                                </button>
                            </div>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <div className="dailyJournal-board">
                                    {[
                                        { id: 'todo', title: 'To Do' },
                                        { id: 'inProgress', title: 'In Progress' },
                                        { id: 'complete', title: 'Complete' }
                                    ].map((column) => (
                                        <Droppable key={column.id} droppableId={column.id}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className={`dailyJournal-column ${snapshot.isDraggingOver ? 'dailyJournal-dragging-over' : ''}`}
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    <div className="dailyJournal-column-header">
                                                        <h3 className="dailyJournal-column-title">
                                                            {column.title}
                                                            <div className="dailyJournal-story-points-badge-header">
                                                                {getTotalStoryPoints(tasks[column.id])}
                                                            </div>
                                                        </h3>
                                                    </div>
                                                    <ul className="list-group">
                                                        {getSortedTasks(tasks[column.id]).map((task, index) => (
                                                            <Draggable key={task._id} draggableId={task._id} index={index}>
                                                                {(provided) => (
                                                                    <li
                                                                        className="list-group-item dailyJournal-task-item"
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div className="dailyJournal-task-content">
                                                                            <span className="dailyJournal-task-text">{task.content}</span>
                                                                            <div className="dailyJournal-badges">
                                                                                {editingCriticality === task._id ? (
                                                                                    <select
                                                                                        className="dailyJournal-criticality-select"
                                                                                        value={task.criticality || 'L'}
                                                                                        onChange={(e) => handleCriticalityChange(task._id, column.id, e.target.value)}
                                                                                        onClick={(e) => e.stopPropagation()}
                                                                                        autoFocus
                                                                                        onBlur={() => setEditingCriticality(null)}
                                                                                    >
                                                                                        <option value="L">L</option>
                                                                                        <option value="M">M</option>
                                                                                        <option value="H">H</option>
                                                                                    </select>
                                                                                ) : (
                                                                                    <div 
                                                                                        className={`dailyJournal-criticality-badge criticality-${getCriticalityLabel(task.criticality || 'L').toLowerCase()}`}
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            setEditingCriticality(task._id);
                                                                                        }}
                                                                                        title={`Criticality: ${getCriticalityLabel(task.criticality || 'L')}`}
                                                                                    >
                                                                                        {task.criticality || 'L'}
                                                                                    </div>
                                                                                )}
                                                                                {editingPoints === task._id ? (
                                                                                    <input
                                                                                        type="number"
                                                                                        className="dailyJournal-story-points-input"
                                                                                        value={pointsContent}
                                                                                        onChange={(e) => setPointsContent(e.target.value)}
                                                                                        onBlur={() => handleStoryPointsBlur(task._id, column.id)}
                                                                                        onClick={(e) => e.stopPropagation()}
                                                                                        autoFocus
                                                                                        style={{ width: '30px' }} // Add fixed width
                                                                                    />
                                                                                ) : (
                                                                                    <div 
                                                                                        className={`dailyJournal-story-points-badge sp-${task.storyPoints || 0}`}
                                                                                        onClick={(e) => handleStoryPointsEdit(e, task._id, task.storyPoints)}
                                                                                        title="Click to edit story points"
                                                                                    >
                                                                                        {task.storyPoints || '0'}
                                                                                    </div>
                                                                                )}
                                                                                <button
                                                                                    className="dailyJournal-details-button"
                                                                                    onClick={() => openModal(task)}
                                                                                    aria-label="View Details"
                                                                                >
                                                                                    <FaInfoCircle />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <button
                                                                            className="dailyJournal-delete-button"
                                                                            onClick={() => handleDeleteTask(task._id, column.id)}
                                                                        >
                                                                            &times;
                                                                        </button>
                                                                    </li>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </ul>
                                                </div>
                                            )}
                                        </Droppable>
                                    ))}
                                </div>
                            </DragDropContext>
                        </div>
                    </div>
                    <div className="dailyJournal-notes-section">
                        <h3 className="dailyJournal-notes-label">Daily Notes</h3>
                        <div className="dailyJournal-editor-container">
                            <div id="editor-journal"></div> {/* Quill editor location */}
                        </div>
                    </div>
                </main>
                <Modal
                    show={modalIsOpen}
                    onHide={closeModal}
                    centered
                    dialogClassName="dailyJournal-modal-dialog"
                    contentClassName="dailyJournal-modal-content"
                    className="dailyJournal-modal"
                >
                    {selectedTask && (
                        <>
                            <Modal.Header closeButton className="dailyJournal-modal-header">
                                <textarea
                                    className="dailyJournal-modal-title full-width dailyJournal-modal-title-styled"
                                    rows={2}
                                    value={modalTaskTitle}
                                    onChange={(e) => setModalTaskTitle(e.target.value)}
                                />
                            </Modal.Header>
                            <Modal.Body className="dailyJournal-modal-body">
                                <div className="dailyJournal-modal-split">
                                    <div className="dailyJournal-modal-left">
                                        <div className="mb-3">
                                            <label className="dailyJournal-modal-label">Story Points:</label>
                                            <input
                                                type="number"
                                                className="dailyJournal-modal-form-control"
                                                value={modalStoryPoints}
                                                onChange={(e) => setModalStoryPoints(e.target.value)}
                                                min="0"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="dailyJournal-modal-label">Criticality:</label>
                                            <select
                                                className={`dailyJournal-criticality-select ${
                                                    modalCriticality === 'Low' ? 'criticality-low'
                                                    : modalCriticality === 'Medium' ? 'criticality-medium'
                                                    : 'criticality-high'
                                                }`}
                                                value={modalCriticality}
                                                onChange={(e) => setModalCriticality(e.target.value)}
                                            >
                                                <option value="Low">Low</option>
                                                <option value="Medium">Medium</option>
                                                <option value="High">High</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="dailyJournal-modal-label">Due Date:</label>
                                            <input
                                                type="date"
                                                className="dailyJournal-modal-date"
                                                value={taskDueDate}
                                                onChange={(e) => setTaskDueDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="dailyJournal-modal-right">
                                        <div className="dailyJournal-modal-notes">
                                            <label className="dailyJournal-modal-label">Notes:</label>
                                            <textarea
                                                className="dailyJournal-modal-textarea"
                                                value={taskNotes}
                                                onChange={(e) => setTaskNotes(e.target.value)}
                                                placeholder="Add your notes here..."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="dailyJournal-modal-footer">
                                <Button 
                                    variant="primary" 
                                    onClick={handleSaveTaskDetails}
                                    className="dailyJournal-modal-button dailyJournal-modal-save"
                                >
                                    Save Changes
                                </Button>
                                <Button 
                                    variant="secondary" 
                                    onClick={closeModal}
                                    className="dailyJournal-modal-button dailyJournal-modal-close"
                                >
                                    Close
                                </Button>
                            </Modal.Footer>
                        </>
                    )}
                </Modal>
            </div>
            <SlidingPane
                className="dailyJournal-sliding-pane" // Renamed className for uniqueness
                isOpen={isAnalyticsOpen}
                title="Analytics"
                from="right"
                width="600px" // Increased width for better chart display
                onRequestClose={closeAnalyticsPane}
            >
                {analyticsData && analyticsData.length > 0 ? ( // Updated condition to check for data
                    <div>
                        <Line 
                            // ref={chartRef} // Remove the ref from the chart
                            data={{
                                labels: analyticsData.map(entry => formatDate(entry.date)), // Updated formatting
                                datasets: [
                                    {
                                        label: 'Total Story Points',
                                        data: analyticsData.map(entry => entry.totalPoints),
                                        fill: false,
                                        backgroundColor: 'rgb(0, 122, 255)',     // Changed to blue
                                        borderColor: 'rgba(0, 122, 255, 0.8)',   // Changed to blue
                                        borderWidth: 3,
                                    },
                                    {
                                        label: 'Completed Story Points',
                                        data: analyticsData.map(entry => entry.completedPoints),
                                        fill: false,
                                        backgroundColor: 'rgb(40, 167, 69)',     // Changed to green
                                        borderColor: 'rgba(40, 167, 69, 0.8)',   // Changed to green
                                        borderWidth: 3,
                                    },
                                    {
                                        label: 'Todo Story Points',
                                        data: analyticsData.map(entry => entry.todoPoints),
                                        fill: false,
                                        backgroundColor: 'rgb(255, 206, 86)',    // Kept yellow
                                        borderColor: 'rgba(255, 206, 86, 0.8)',  // Kept yellow
                                        borderWidth: 3,
                                    },
                                    {
                                        label: 'Average Points Per Day',
                                        data: Array(analyticsData.length).fill(
                                            analyticsData.reduce((sum, entry) => sum + entry.totalPoints, 0) / analyticsData.length
                                        ),
                                        fill: false,
                                        backgroundColor: 'rgb(128, 128, 128)',
                                        borderColor: 'rgba(128, 128, 128, 0.8)',
                                        borderWidth: 2,
                                        borderDash: [5, 5], // Add dashed line style
                                    },
                                    {
                                        label: 'Average Completed Points Per Day',
                                        data: Array(analyticsData.length).fill(
                                            analyticsData.length ? analyticsData.reduce((sum, entry) => sum + entry.completedPoints, 0) / analyticsData.length : 0
                                        ),
                                        fill: false,
                                        backgroundColor: 'rgb(128, 128, 128)',     // Grey color
                                        borderColor: 'rgba(128, 128, 128, 0.8)',   // Grey color
                                        borderWidth: 2,
                                        borderDash: [5, 5],                         // Dashed line style
                                    },
                                ],
                            }}
                            options={lineOptions}
                        />
                        
                        {/* Add the stacked Bar chart */}
                        <Bar 
                            data={barData}
                            options={barOptions}
                            className="dailyJournal-bar-chart"
                        />
                    </div>
                ) : (
                    <p>No data available for the selected period.</p> // Updated message
                )}
            </SlidingPane>
        </>
    );
};

export default DailyJournal;