import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NavbarAuth from './Navbar_Authenticated';
import ProjectNavbar from './ProjectNavbar';
import "./ComponentStyling/Kanban.css";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import ReactGA from 'react-ga4';

const Kanban = () => {
    const { projectId } = useParams();
    const [tasks, setTasks] = useState({
        todo: [],
        inProgress: [],
        complete: []
    });
    const [newTask, setNewTask] = useState('');
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/kanbanTasks`);
                const fetchedTasks = response.data;
                setTasks({
                    todo: fetchedTasks.filter(task => task.status === 'todo'),
                    inProgress: fetchedTasks.filter(task => task.status === 'inProgress'),
                    complete: fetchedTasks.filter(task => task.status === 'complete')
                });
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, [projectId]);

    const handleAddTask = async () => {
        if (newTask.trim()) {
            const taskToAdd = { content: newTask, status: 'todo', projectId };
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/kanbanTasks`, taskToAdd);
                if (response.status === 201) {
                    setTasks(prev => ({
                        ...prev,
                        todo: [...prev.todo, { ...taskToAdd, _id: response.data._id }]
                    }));
                    setNewTask('');
                }
            } catch (error) {
                console.error('Error adding task:', error);
            }
        }
    };

    const handleDeleteTask = async (taskId, columnId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/kanbanTasks/${taskId}`);
            setTasks(prev => ({
                ...prev,
                [columnId]: prev[columnId].filter(task => task._id !== taskId)
            }));
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const handleDragEnd = async (result) => {
        console.log(result);
        if (!result.destination) return;

        const { source, destination } = result;

        if (source.droppableId === destination.droppableId) {
            const column = Array.from(tasks[source.droppableId]);
            const [removed] = column.splice(source.index, 1);
            column.splice(destination.index, 0, removed);

            setTasks(prev => ({
                ...prev,
                [source.droppableId]: column
            }));
        } else {
            const sourceColumn = Array.from(tasks[source.droppableId]);
            const destColumn = Array.from(tasks[destination.droppableId]);
            const [removed] = sourceColumn.splice(source.index, 1);
            destColumn.splice(destination.index, 0, removed);

            setTasks(prev => ({
                ...prev,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destColumn
            }));

            // Update task status on the server
            try {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/kanbanTasks/${removed._id}`, {
                    ...removed,
                    status: destination.droppableId
                });
            } catch (error) {
                console.error('Error updating task status:', error);
            }
        }
    };

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="container-fluid">
                <div className="row">
                    <main className="col-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Kanban</h1>
                        </div>
                        <div className="kanban-input">
                            <input
                                type="text"
                                className="form-control"
                                value={newTask}
                                onChange={(e) => setNewTask(e.target.value)}
                                placeholder="Enter new task"
                            />
                            <button className="btn btn-primary addTask" onClick={handleAddTask}>Add Task</button>
                        </div>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <div className="kanban-board">
                                {[
                                    { id: 'todo', title: 'To Do' },
                                    { id: 'inProgress', title: 'In Progress' },
                                    { id: 'complete', title: 'Complete' }
                                ].map((column) => (
                                    <Droppable key={column.id} droppableId={column.id}>
                                        {(provided, snapshot) => (
                                            <div
                                                className={`kanban-column ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                <h3>{column.title}</h3>
                                                <ul className="list-group">
                                                    {tasks[column.id].map((task, index) => (
                                                        <Draggable key={task._id} draggableId={task._id} index={index}>
                                                            {(provided) => (
                                                                <li
                                                                    className="list-group-item"
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    {task.content}
                                                                    <button
                                                                        className="delete-button"
                                                                        onClick={() => handleDeleteTask(task._id, column.id)}
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </ul>
                                            </div>
                                        )}
                                    </Droppable>
                                ))}
                            </div>
                        </DragDropContext>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default Kanban;