import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import DOMPurify from 'dompurify'; // Import DOMPurify
import axiosInstance from '../axiosConfig'; // Import the configured Axios instance

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // Check authentication status on mount
        const checkAuth = async () => {
            try {
                const response = await axiosInstance.get('/auth/status');
                const sanitizedUser = response.data.userData ? {
                    user_id: DOMPurify.sanitize(response.data.userData.user_id),
                    email: DOMPurify.sanitize(response.data.userData.email), // Store email
                    // ...sanitize other userData fields as needed
                } : null;
                setUser(sanitizedUser);
                setIsAuthenticated(!!sanitizedUser);
            } catch (e) {
                console.error('Auth check failed:', e.response ? e.response.data : e.message);
                setUser(null);
                setIsAuthenticated(false);
            }
        };
        checkAuth();
    }, []);

    const login = (userData) => {
        const sanitizedUserData = {
            user_id: DOMPurify.sanitize(userData.user_id),
            email: DOMPurify.sanitize(userData.email), // Store email
            // ...sanitize other userData fields as needed
        };
        setUser(sanitizedUserData);
        setIsAuthenticated(true);
        // No need to store token in localStorage
    };

    const logout = async () => {
        try {
            await axiosInstance.post('/logout');
        } catch (e) {
            console.error('Logout failed:', e.response ? e.response.data : e.message);
        }
        setUser(null);
        setIsAuthenticated(false);
        console.log('User successfully logged out');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}