import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavbarAuth from '../Components/Navbar_Authenticated';
import { useAuth } from '../Components/AuthContext.js';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement } from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PageCSS/Dashboard.css';
import Footer from "../Components/Footer";
import ReactGA from 'react-ga4';

// Register the CategoryScale, LinearScale, and BarElement
Chart.register(CategoryScale, LinearScale, BarElement);

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true, // Ensure withCredentials is set globally
});

export default function Dashboard() {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeProject, setActiveProject] = useState(null); // Change hoveredProject to activeProject
    const [userProfile, setUserProfile] = useState(null);
    const [upcomingTasks, setUpcomingTasks] = useState([]);
    const [overdueTasks, setOverdueTasks] = useState([]);
    const [noDueDateTasks, setNoDueDateTasks] = useState([]);
    const { user, isAuthenticated } = useAuth();

    useEffect(() => {
        let isMounted = true;

        if (isAuthenticated && user) {
            fetchProjects(user.user_id, isMounted);
            fetchUserProfile(user.user_id, isMounted);
        }

        return () => {
            isMounted = false;
        };
    }, [isAuthenticated, user]);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    const fetchProjects = async (userId, isMounted) => {
        try {
            const response = await axiosInstance.get(`/api/projects/${userId}`);
            if (isMounted) {
                setProjects(response.data);
                fetchUpcomingTasks(response.data);
            }
        } catch (e) {
            if (isMounted) {
                handleError(e);
            }
        } finally {
            if (isMounted) {
                setLoading(false);
            }
        }
    };

    const fetchUserProfile = async (userId, isMounted) => {
        try {
            console.log('Fetching user profile for user ID:', userId);
            const response = await axiosInstance.get(`/api/user/${userId}`, { withCredentials: true });
            console.log('User profile fetched:', response.data);
            if (isMounted) {
                setUserProfile(response.data);
            }
        } catch (e) {
            console.error('Error fetching user profile:', e);
            if (e.response) {
                console.error('Response status:', e.response.status);
                console.error('Response data:', e.response.data);
            } else if (e.request) {
                console.error('Network error:', e.request);
            } else {
                console.error('Error:', e.message);
            }
        }
    };

    const fetchUpcomingTasks = async (projects) => {
        try {
            const projectIds = projects.map(project => project._id);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/raidlog/fetchRaidLogs`, { projectIds }, { withCredentials: true });
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const upcomingTasks = filterTasks(response.data, today, 7, projects);
            const overdueTasks = filterTasks(response.data, today, -1, projects, true);
            const noDueDateTasks = response.data.filter(task => !task.due).map(task => ({
                ...task,
                projectName: projects.find(project => project._id === task.projectId)?.project_name || 'Unknown Project'
            }));

            setUpcomingTasks(upcomingTasks);
            setOverdueTasks(overdueTasks);
            setNoDueDateTasks(noDueDateTasks);
        } catch (e) {
            console.error('Error fetching tasks:', e);
        }
    };

    const filterTasks = (tasks, today, days, projects, isOverdue = false) => {
        return tasks.filter(task => {
            if (!task.due) return false;
            const dueDate = new Date(task.due);
            dueDate.setHours(0, 0, 0, 0);
            dueDate.setDate(dueDate.getDate() + 1);

            if (isOverdue) {
                return dueDate < today && task.status !== 'Complete';
            } else {
                const futureDate = new Date(today);
                futureDate.setDate(today.getDate() + days);
                return dueDate >= today && dueDate <= futureDate && task.status !== 'Complete';
            }
        }).map(task => ({
            ...task,
            projectName: projects.find(project => project._id === task.projectId)?.project_name || 'Unknown Project'
        })).sort((a, b) => new Date(a.due) - new Date(b.due));
    };

    const handleError = (e) => {
        if (e.response) {
            console.error('Error fetching projects: ', e);
            setError('Error fetching projects');
        } else if (e.request) {
            console.error('Network error:', e.request);
            setError('Network error');
        } else {
            console.error('Error:', e.message);
            setError('An error occurred');
        }
    };

    const handleProjectClick = (projectId) => {
        setActiveProject(activeProject === projectId ? null : projectId); // Toggle active project
    };

    const getGreeting = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            return 'Good morning';
        } else if (currentHour < 18) {
            return 'Good afternoon';
        } else {
            return 'Good evening';
        }
    };

    const adjustDateForDisplay = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        return date.toLocaleDateString();
    };

    const data = {
        labels: ['Upcoming Tasks', 'Overdue Tasks', 'No Due Date'],
        datasets: [
            {
                label: 'Number of Tasks',
                data: [upcomingTasks.length, overdueTasks.length, noDueDateTasks.length],
                backgroundColor: ['blue', 'red', 'gray'],
            },
        ],
    };

    const options = {
        scales: {
            y: {
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    return (
        <div className="App">
            <NavbarAuth />
            <div className="dashboard-container-fluid">
                <div className="row">
                    <main className="dashboard-main-content col-12 px-md-4">
                        <h1 className="text-center mb-4 pt-5">
                            {userProfile ? `${getGreeting()}, ${userProfile.firstname}` : 'Project Dashboard'}
                        </h1>
                        <div className="dashboard-d-flex">
                            <Link to="/newProject" className="btn btn-primary me-2">New Project</Link>
                            <Link to={`/${user.user_id}/dailyJournal`} className="btn btn-primary dailyJournal">Daily Journal</Link>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-8 dashboard-d-flex">
                                <div className="dashboard-card dashboard-flex-fill">
                                    <div className="dashboard-card-body">
                                        <h5 className="card-title">Project Status</h5>
                                        {projects.length === 0 ? (
                                            <p>No projects found</p>
                                        ) : (
                                            <ul className="list-group">
                                                {projects.map((project) => (
                                                    <li key={project._id} className="dashboard-list-group-item d-flex flex-column"
                                                        onClick={() => handleProjectClick(project._id)}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <span>{project.project_name}</span>
                                                            <span className={`dashboard-status-circle ${project.status ? project.status.toLowerCase() : ''}`}></span>
                                                        </div>
                                                        {activeProject === project._id && (
                                                            <ul className="nav flex-column dashboard-project-submenu mt-2">
                                                                <li className="nav-item">
                                                                    <Link className="dashboard-nav-link ms-3" to={`/projects/${project._id}`}>Project Overview</Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link className="dashboard-nav-link ms-3" to={`/projects/${project._id}/stakeholders`}>Stakeholders</Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link className="dashboard-nav-link ms-3" to={`/projects/${project._id}/raidlog`}>RAID Log</Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link className="dashboard-nav-link ms-3" to={`/projects/${project._id}/settings`}>Project Settings</Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link className="dashboard-nav-link ms-3" to={`/projects/${project._id}/kanban`}>Kanban Board</Link>
                                                                </li>
                                                            </ul>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 dashboard-d-flex">
                                <div className="dashboard-card dashboard-flex-fill">
                                    <div className="dashboard-card-body">
                                        <h5 className="card-title">Task Distribution</h5>
                                        <Bar data={data} options={options} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dashboard-card">
                                    <div className="dashboard-card-body">
                                        <h5 className="card-title">Upcoming Tasks</h5>
                                        {upcomingTasks.length === 0 ? (
                                            <p>No upcoming tasks</p>
                                        ) : (
                                            <div className="dashboard-table-responsive">
                                                <table className="dashboard-table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Due Date</th>
                                                            <th>Owner</th>
                                                            <th>Status</th>
                                                            <th>Project</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {upcomingTasks.map((task, index) => (
                                                            <tr key={index}>
                                                                <td className="text-wrap dashboard-action-column">
                                                                    <Link className="dashboard-upcoming-raidlog-items" to={`/projects/${task.projectId}/raidlog`}>
                                                                        {task.action}
                                                                    </Link>
                                                                </td>
                                                                <td>{adjustDateForDisplay(task.due)}</td>
                                                                <td>{task.owner}</td>
                                                                <td>{task.status}</td>
                                                                <td>{task.projectName}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dashboard-card">
                                    <div className="dashboard-card-body">
                                        <h5 className="card-title">Overdue Tasks</h5>
                                        {overdueTasks.length === 0 ? (
                                            <p>No overdue tasks</p>
                                        ) : (
                                            <div className="dashboard-table-responsive">
                                                <table className="dashboard-table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Due Date</th>
                                                            <th>Owner</th>
                                                            <th>Status</th>
                                                            <th>Project</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {overdueTasks.map((task, index) => (
                                                            <tr key={index} className="dashboard-overdue-task">
                                                                <td className="text-wrap dashboard-action-column">
                                                                    <Link className="dashboard-overdue-raidlog-items" to={`/projects/${task.projectId}/raidlog`}>
                                                                        {task.action}
                                                                    </Link>
                                                                </td>
                                                                <td>{adjustDateForDisplay(task.due)}</td>
                                                                <td>{task.owner}</td>
                                                                <td>{task.status}</td>
                                                                <td>{task.projectName}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <div className="row">
                <Footer />
            </div>
        </div>
    );
};