import "./ComponentStyling/ProjectPage.css";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import ProjectNavbar from './ProjectNavbar';
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';

const ProjectPage = () => {
    const { projectId } = useParams(); // Get projectId from URL
    const [project, setProject] = useState(null);
    const [stakeholders, setStakeholders] = useState([]);
    const [raidlog, setRAID] = useState([]);
    const [projectStatus, setProjectStatus] = useState('');
    const [description, setDescription] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [projectState, setProjectState] = useState('');
    const [links, setLinks] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/id/${projectId}`);
                setProject(response.data);
                setProjectStatus(response.data.status); // Initialize project status
                setDescription(response.data.description); // Initialize description
                setCreatedDate(response.data.created_date); // Initialize created date
                setProjectState(response.data.projectState); // Initialize project state
            } catch (error) {
                console.error('Error fetching project:', error);
            }
        };

        const fetchStakeholders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/stakeholders`);
                setStakeholders(response.data);
            } catch (error) {
                console.error('Error fetching stakeholders:', error);
            }
        };

        const fetchRAID = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/raidlog`);
                setRAID(response.data);
            } catch (error) {
                console.error('Error fetching RAID log:', error);
            }
        };

        const fetchMeetings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/meetings/${projectId}`);
                const meetings = response.data.map(meeting => ({
                    id: meeting.meetingId,
                    text: meeting.meetingTitle, // Use meetingTitle
                    date: meeting.date,
                    time: meeting.time,
                    url: `/projects/${projectId}/${meeting.meetingId}`
                }));
                setLinks(meetings);
            } catch (error) {
                console.error('Error fetching meetings:', error);
                console.error('Response data:', error.response?.data);
            }
        };

        fetchProject();
        fetchStakeholders();
        fetchRAID();
        fetchMeetings();
    }, [projectId]);

    console.log(projectId);

    const handleStatusUpdate = async () => {
        console.log('Updating project state to:', projectState); // Log the project state
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
                status: projectStatus,
                description: description,
                created_date: createdDate,
                projectState: projectState
            });
            console.log('Project updated', response.data);
            setProject({ ...project, status: projectStatus, description: description, created_date: createdDate, projectState: projectState });
        } catch (error) {
            console.error('Error updating project:', error.response ? error.response.data : error.message);
        }
    };

    const addLink = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/meetings`, { projectId });
            const newMeeting = response.data;

            const newLink = {
                id: newMeeting.meetingId,
                text: newMeeting.meetingTitle, // Use meetingTitle
                date: newMeeting.date,
                time: newMeeting.time,
                url: `/projects/${projectId}/${newMeeting.meetingId}`
            };

            setLinks([...links, newLink]);
        } catch (error) {
            console.error('Error creating meeting:', error);
            console.error('Response data:', error.response?.data);
        }
    };

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate() + 1}`.padStart(2, '0');
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="container-fluid">
                <div className="row">
                    <main className="main-content col-12 px-md-4">
                        {project ? (
                            <>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h1 className="h2">
                                        {project.project_name}
                                        <span className={`status-circle status-${projectStatus.toLowerCase()}`}></span>
                                    </h1>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <label htmlFor="projectStatus" className="form-label">Project Status</label>
                                                <select
                                                    className={`form-select status-${projectStatus.toLowerCase()} project-status-select`}
                                                    id="projectStatus"
                                                    value={projectStatus}
                                                    onChange={(e) => setProjectStatus(e.target.value)}
                                                    onBlur={handleStatusUpdate}
                                                >
                                                    <option value="Green">Green</option>
                                                    <option value="Yellow">Yellow</option>
                                                    <option value="Red">Red</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <label htmlFor="createdDate" className="form-label">Created Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="createdDate"
                                                    value={createdDate}
                                                    onChange={(e) => setCreatedDate(e.target.value)}
                                                    onBlur={handleStatusUpdate}
                                                />
                                            </div>
                                        </div>
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <label htmlFor="projectState" className="form-label">Project State</label>
                                                <select
                                                    className="form-select"
                                                    id="projectState"
                                                    value={projectState}
                                                    onChange={(e) => setProjectState(e.target.value)}
                                                    onBlur={handleStatusUpdate}
                                                >
                                                    <option value="Archived">Archived</option>
                                                    <option value="Not Started">Not Started</option>
                                                    <option value="In Progress">In Progress</option>
                                                    <option value="Cancelled">Cancelled</option>
                                                    <option value="Completed">Completed</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <label htmlFor="description" className="form-label">Description</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="description"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    onBlur={handleStatusUpdate}
                                                />
                                            </div>
                                        </div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <h2>Meeting Notes</h2>
                                                    <button className="btn btn-primary" onClick={addLink}>+</button>
                                                </div>
                                                <ul className="list-group">
                                                    {links.map(link => (
                                                        <li key={link.id} className="list-group-item">
                                                            <Link to={link.url}>{link.text}</Link>
                                                            <div className="small text-muted">Date: {formatDate(link.date)}, Time: {link.time}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="alert alert-danger mt-4">Project not found</div>
                        )}
                    </main>
                </div>
            </div>
            <div className="row">
                <Footer />
            </div>
        </div>
    );
};

export default ProjectPage;