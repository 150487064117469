
import axios from 'axios';

// Create an Axios instance with default settings
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true, // Include cookies in requests
});

// Optionally, add interceptors if needed
// axiosInstance.interceptors.request.use(/* ... */);

export default axiosInstance;