import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import ProjectNavbar from './ProjectNavbar';
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ComponentStyling/ProjectSettings.css';
import { GoPlus } from "react-icons/go";
import { FaTrash } from "react-icons/fa"; // Import trash icon
import ReactGA from 'react-ga4';

const ProjectSettings = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [deleteProjectName, setDeleteProjectName] = useState('');
    const [stakeholderColumns, setStakeholderColumns] = useState([]);
    const [customStakeholderColumns, setCustomStakeholderColumns] = useState([]);
    const [raidLogColumns, setRaidLogColumns] = useState({
        Risks: ['Risk', 'Identified Date', 'Risk Impact', 'Risk Probability', 'Risk Score', 'Mitigation Plan', 'Comments'],
        Actions: ['Action', 'Owner', 'Status', 'Priority', 'Due'],
        Issues: ['Issue', 'Severity', 'Status', 'Identified Date', 'Action Plan', 'Comments'],
        Decisions: ['Decision', 'Made By', 'Decision Date', 'Status']
    });
    const [showPopup, setShowPopup] = useState(false);
    const [newColumnName, setNewColumnName] = useState('');
    const [selectedTab, setSelectedTab] = useState('Risks'); // State to track selected tab for custom columns
    const navigate = useNavigate();

    const predefinedColumns = ['Name', 'Role', 'Email', 'Category', 'Power', 'Influence']; // Updated pre-defined columns
    const predefinedRaidLogColumns = {
        Risks: ['Risk', 'Identified Date', 'Risk Impact', 'Risk Probability', 'Risk Score', 'Mitigation Plan', 'Comments'],
        Actions: ['Action', 'Owner', 'Status', 'Priority', 'Due'],
        Issues: ['Issue', 'Severity', 'Status', 'Identified Date', 'Action Plan', 'Comments'],
        Decisions: ['Decision', 'Made By', 'Decision Date', 'Status']
    };

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/id/${projectId}`);
                setProject(response.data);
                setProjectName(response.data.project_name);
                setProjectDescription(response.data.description);
            } catch (error) {
                console.error('Error fetching project:', error);
            }
        };

        const fetchProjectSettings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projectSettings/${projectId}`);
                setStakeholderColumns(response.data.stakeholderColumns || []);
                setCustomStakeholderColumns(response.data.customStakeholderColumns || []);
                setRaidLogColumns(response.data.raidLogColumns || raidLogColumns); // Ensure raidLogColumns is defined
            } catch (error) {
                console.error('Error fetching project settings:', error);
            }
        };

        fetchProject();
        fetchProjectSettings();
    }, [projectId]);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    const handleUpdateProject = async () => {
        try {
            console.log('Updating project with data:', {
                project_name: projectName,
                description: projectDescription
            });
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
                project_name: projectName,
                description: projectDescription
            });
            setProject(response.data);
            alert('Project updated successfully');
        } catch (error) {
            console.error('Error updating project:', error);
            alert('Failed to update project');
        }
    };

    const handleDeleteProject = async () => {
        if (deleteProjectName !== projectName) {
            alert('Project name does not match. Please type the correct project name to delete');
            return;
        }

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
                data: { projectName: deleteProjectName }
            });
            navigate('/dashboard');
        } catch (error) {
            console.error('Error deleting project:', error);
            alert('Failed to delete project');
        }
    };

    const handleBlur = async (field) => {
        try {
            const updateData = field === 'project_name' ? { project_name: projectName } : { description: projectDescription };
            console.log('Updating project with data on blur:', updateData);
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, updateData);
            setProject(response.data);
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };

    const handleColumnChange = async (column) => {
        const updatedColumns = stakeholderColumns.includes(column)
            ? stakeholderColumns.filter(col => col !== column)
            : stakeholderColumns.includes(column) ? stakeholderColumns : [...stakeholderColumns, column]; // Add to the end if re-added

        setStakeholderColumns(updatedColumns);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, {
                projectId,
                stakeholderColumns: updatedColumns,
                customStakeholderColumns,
                raidLogColumns
            });
        } catch (error) {
            console.error('Error updating project settings:', error);
        }
    };

    const handleRaidLogColumnChange = async (tab, column) => {
        const updatedColumns = raidLogColumns[tab].includes(column)
            ? raidLogColumns[tab].filter(col => col !== column)
            : [...raidLogColumns[tab], column];

        const newRaidLogColumns = { ...raidLogColumns, [tab]: updatedColumns };
        setRaidLogColumns(newRaidLogColumns);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, {
                projectId,
                stakeholderColumns,
                customStakeholderColumns,
                raidLogColumns: newRaidLogColumns
            });
        } catch (error) {
            console.error('Error updating RAID log columns:', error);
        }
    };

    const handleAddColumn = async () => {
        if (newColumnName.trim() === '') {
            alert('Column name cannot be empty');
            return;
        }

        const updatedCustomColumns = [newColumnName, ...customStakeholderColumns];
        setCustomStakeholderColumns(updatedCustomColumns);
        setShowPopup(false);
        setNewColumnName('');

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, {
                projectId,
                stakeholderColumns,
                customStakeholderColumns: updatedCustomColumns,
                raidLogColumns
            });
        } catch (error) {
            console.error('Error adding new column:', error);
        }
    };

    const handleDeleteCustomColumn = async (column) => {
        const updatedCustomColumns = customStakeholderColumns.filter(col => col !== column);
        const updatedColumns = stakeholderColumns.filter(col => col !== column);

        setCustomStakeholderColumns(updatedCustomColumns);
        setStakeholderColumns(updatedColumns);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, {
                projectId,
                stakeholderColumns: updatedColumns,
                customStakeholderColumns: updatedCustomColumns,
                raidLogColumns
            });
        } catch (error) {
            console.error('Error deleting custom column:', error);
        }
    };

    const handleAddCustomColumn = async () => {
        if (newColumnName.trim() === '') {
            alert('Column name cannot be empty');
            return;
        }

        const updatedRaidLogColumns = {
            ...raidLogColumns,
            [selectedTab]: [...raidLogColumns[selectedTab], newColumnName]
        };

        setRaidLogColumns(updatedRaidLogColumns);
        setShowPopup(false);
        setNewColumnName('');

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, {
                projectId,
                stakeholderColumns,
                customStakeholderColumns,
                raidLogColumns: updatedRaidLogColumns
            });
        } catch (error) {
            console.error('Error adding new custom column:', error);
        }
    };

    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="container-fluid">
                <div className="row">
                    <main className="main-content col-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Project Settings</h1>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card mb-3">
                                    <div className="project-settings-card-body">
                                        <label htmlFor="projectName" className="form-label">Project Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="projectName"
                                            value={projectName}
                                            onChange={(e) => setProjectName(e.target.value)}
                                            onBlur={() => handleBlur('project_name')}
                                        />
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className="project-settings-card-body">
                                        <label htmlFor="projectDescription" className="form-label">Project Description</label>
                                        <textarea
                                            className="form-control"
                                            id="projectDescription"
                                            rows="5"
                                            value={projectDescription}
                                            onChange={(e) => setProjectDescription(e.target.value)}
                                            onBlur={() => handleBlur('description')}
                                        ></textarea>
                                    </div>
                                </div>
                                <button className="btn btn-primary" onClick={handleUpdateProject}>Update Project</button>
                                <div className="card mt-4">
                                    <div className="project-settings-card-body">
                                        <h2>Delete Project</h2>
                                        <input
                                            type='text'
                                            className="form-control mb-3"
                                            placeholder='Type project name to delete'
                                            value={deleteProjectName}
                                            onChange={(e) => setDeleteProjectName(e.target.value)}
                                            required
                                        />
                                        <button className="btn btn-danger" onClick={handleDeleteProject}>Delete Project</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card mb-3">
                                    <div className="project-settings-card-body">
                                        <h2>Stakeholder Register Columns</h2>
                                        <h3 className="h5">Pre-Defined</h3>
                                        <ul className="project-settings-card-body">
                                            {predefinedColumns.map((column, index) => (
                                                <li key={index} className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={stakeholderColumns.includes(column)}
                                                        onChange={() => handleColumnChange(column)}
                                                    />
                                                    <span className="ms-2">{column}</span>
                                                </li>
                                            ))}
                                        </ul>
                                        <h3 className="h5">Custom Columns</h3>
                                        <ul className="project-settings-card-body">
                                            {customStakeholderColumns.map((column, index) => (
                                                <li key={index} className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={stakeholderColumns.includes(column)}
                                                        onChange={() => handleColumnChange(column)}
                                                    />
                                                    <span className="ms-2">{column}</span>
                                                    <button className="btn btn-link text-danger ms-auto" onClick={() => handleDeleteCustomColumn(column)}>
                                                        <FaTrash />
                                                    </button>
                                                </li>
                                            ))}
                                            <li className="add-column">
                                                <button className="btn btn-link" onClick={() => setShowPopup(true)}>
                                                    <GoPlus />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className="project-settings-card-body">
                                        <h2>RAID Log Columns</h2>
                                        {['Risks', 'Actions', 'Issues', 'Decisions'].map((tab, tabIndex) => {
                                            const allColumns = [...new Set([...predefinedRaidLogColumns[tab], ...raidLogColumns[tab]])];
                                            return (
                                                <div key={tabIndex}>
                                                    <h3 className="h5">{tab}</h3>
                                                    <ul className="project-settings-card-body">
                                                        {allColumns.map((column, index) => (
                                                            <li key={index} className="d-flex align-items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={raidLogColumns[tab].includes(column)}
                                                                    onChange={() => handleRaidLogColumnChange(tab, column)}
                                                                />
                                                                <span className="ms-2">{column}</span>
                                                                {!predefinedRaidLogColumns[tab].includes(column) && (
                                                                    <button
                                                                        className="btn btn-link text-danger ms-auto"
                                                                        onClick={() => handleDeleteCustomColumn(column)}
                                                                    >
                                                                        <FaTrash />
                                                                    </button>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className="project-settings-card-body">
                                        <h2>Add Custom Columns to RAID Log</h2>
                                        <div className="mb-3">
                                            <label htmlFor="customColumnName" className="form-label">Column Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="customColumnName"
                                                value={newColumnName}
                                                onChange={(e) => setNewColumnName(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="selectTab" className="form-label">Select Tab</label>
                                            <select
                                                className="form-select"
                                                id="selectTab"
                                                value={selectedTab}
                                                onChange={(e) => setSelectedTab(e.target.value)}
                                            >
                                                {['Risks', 'Actions', 'Issues', 'Decisions'].map((tab, index) => (
                                                    <option key={index} value={tab}>{tab}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <button className="btn btn-primary" onClick={handleAddCustomColumn}>Add Custom Column</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <div className="row">
                <Footer />
            </div>
            {showPopup && (
                <div className="popup">
                    <div className="popup-inner">
                        <h3>Add New Column</h3>
                        <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Column Name"
                            value={newColumnName}
                            onChange={(e) => setNewColumnName(e.target.value)}
                        />
                        <button className="btn btn-primary" onClick={handleAddColumn}>Submit</button>
                        <button className="btn btn-secondary" onClick={() => setShowPopup(false)}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectSettings;
