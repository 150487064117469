import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import ProjectNavbar from './ProjectNavbar';
import "./ComponentStyling/RAIDLog.css";
import ReactGA from 'react-ga4';
import { FaTrash } from "react-icons/fa"; // Import trash icon
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

const RAIDLog = () => {
    const { projectId } = useParams();
    const [raidlog, setRAID] = useState([]);
    const [originalRaidLog, setOriginalRAID] = useState([]);
    const [isEditing, setIsEditing] = useState(null);
    const [columns, setColumns] = useState({
        Risks: ['Risk', 'Identified Date', 'Risk Impact', 'Risk Probability', 'Risk Score', 'Mitigation Plan', 'Comments'],
        Actions: ['Action', 'Owner', 'Status', 'Priority', 'Due'],
        Issues: ['Issue', 'Severity', 'Status', 'Identified Date', 'Action Plan', 'Comments'],
        Decisions: ['Decision', 'Made By', 'Decision Date', 'Status']
    });
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('Risks'); // Changed from 'Actions' to 'Risks' for consistency
    const [editableCell, setEditableCell] = useState({ rowId: null, column: null });
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, raidId: null });
    const [visibleColumns, setVisibleColumns] = useState({
        Risks: [],
        Actions: [],
        Issues: [],
        Decisions: []
    });

    const predefinedColumns = {
        Risks: ['Risk', 'Identified Date', 'Risk Impact', 'Risk Probability', 'Risk Score', 'Mitigation Plan', 'Comments'],
        Actions: ['Action', 'Owner', 'Status', 'Priority', 'Due'],
        Issues: ['Issue', 'Severity', 'Status', 'Identified Date', 'Action Plan', 'Comments'],
        Decisions: ['Decision', 'Made By', 'Decision Date', 'Status']
    };

    useEffect(() => {
        const fetchRAID = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/raidlog`);
                setRAID(response.data);
                setOriginalRAID(response.data);
            } catch (error) {
                console.error('Error fetching RAID log:', error);
            }
        };

        const fetchProjectSettings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projectSettings/${projectId}`);
                const fetchedColumns = response.data.raidLogColumns || predefinedColumns;
                setColumns(fetchedColumns);
                // Remove or comment out code that resets visibleColumns here
                // (e.g., don't automatically set all columns as visible on load)
            } catch (error) {
                console.error('Error fetching project settings:', error);
            }
        };

        fetchRAID();
        fetchProjectSettings();
    }, [projectId]);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenu.visible) {
                setContextMenu({ visible: false, x: 0, y: 0, raidId: null });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [contextMenu.visible]);

    const fetchRAID = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/raidlog`);
            const sortedData = response.data.sort((a, b) => a.raidId - b.raidId);
            setRAID(sortedData);
            setOriginalRAID(sortedData);
        } catch (e) {
            console.error('Error fetching RAID Log:', e);
        }
    };

    const fetchProjectSettings = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projectSettings/${projectId}`);
            if (response.status === 200) {
                setColumns(response.data.raidLogColumns || columns); // Ensure raidLogColumns is defined
            }
        } catch (error) {
            console.error('Error fetching project settings:', error);
        }
    };

    const saveProjectSettings = async (updatedColumns) => {
        const settings = {
            projectId: projectId.toString(),
            raidLogColumns: updatedColumns
        };

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, settings);
            console.log('Project settings saved');
        } catch (error) {
            console.error('Error saving project settings:', error);
        }
    };

    const handleEditChange = (raidId, e) => {
        const { name, value } = e.target;
        const updatedRAIDLog = raidlog.map(item => item.raidId === raidId ? { ...item, [name]: value } : item);
        setRAID(updatedRAIDLog);
    };

    const handleAddRow = () => {
        const newRaidId = raidlog.length > 0 ? Math.max(...raidlog.map(item => item.raidId || 0)) + 1 : 1;
        const newRow = columns[activeTab].reduce((acc, column) => {
            acc[column.toLowerCase()] = '';
            return acc;
        }, { 
            raidId: newRaidId, 
            raidType: activeTab.slice(0, -1),
            identifiedDate: '' // Leave identifiedDate blank on creation
        });
        setRAID([...raidlog, newRow]);
        setOriginalRAID([...raidlog, newRow]); // Ensure original RAID log is updated
        setIsEditing(newRaidId); // Set the new row as the one being edited
    };

    const handleSave = async (raidId) => {
        const raidlogitem = raidlog.find(item => item.raidId === raidId);
        const originalItem = originalRaidLog.find(item => item.raidId === raidId);
        const isNewItem = !raidlogitem._id;

        const itemToSave = {
            ...raidlogitem,
            projectId: projectId.toString(),
            due: raidlogitem.due ? new Date(raidlogitem.due) : '',
            raidType: raidlogitem.raidType || activeTab.slice(0, -1),
            status: raidlogitem.status || (activeTab === 'Actions' ? 'Not Started' : (activeTab === 'Issues' || activeTab === 'Decisions') ? 'Open' : ''),
            priority: raidlogitem.priority || (activeTab === 'Actions' ? 'Low' : ''),
            severity: raidlogitem.severity || (activeTab === 'Issues' ? 'Low' : '')
        };

        const updatedRAIDLog = raidlog.map(item => item.raidId === raidId ? itemToSave : item);
        setRAID(updatedRAIDLog);

        try {
            if (isNewItem) {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/raidlog/newItem`, itemToSave);
                if (response.status === 201) {
                    await fetchRAID();
                    setIsEditing(null);
                }
            } else {
                const hasChanges = Object.keys(raidlogitem).some(key => raidlogitem[key] !== originalItem[key]);
                if (hasChanges) {
                    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/raidlog/${raidlogitem._id}`, itemToSave);
                    if (response.status === 200) {
                        await fetchRAID();
                        setIsEditing(null);
                    }
                } else {
                    setIsEditing(null);
                }
            }
        } catch (error) {
            console.error('Error saving RAID log item:', error);
            setRAID(raidlog);
        }
    };

    const handleDelete = async (raidId) => {
        const raidlogitemToDelete = raidlog.find(item => item.raidId === raidId);
    
        if (!raidlogitemToDelete._id) {
            const updatedRAIDLog = raidlog.filter(item => item.raidId !== raidId).map((item, idx) => ({ ...item, raidId: idx + 1 }));
            setRAID(updatedRAIDLog);
            setOriginalRAID(updatedRAIDLog);
            return;
        }
    
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/raidlog/${raidlogitemToDelete._id}`);
            if (response.status === 200) {
                const updatedRAIDLog = raidlog.filter(item => item.raidId !== raidId).map((item, idx) => ({ ...item, raidId: idx + 1 }));
                setRAID(updatedRAIDLog);
                setOriginalRAID(updatedRAIDLog);
                for (const item of updatedRAIDLog) {
                    try {
                        await axios.put(`${process.env.REACT_APP_API_URL}/api/raidlog/${item._id}`, { raidId: item.raidId });
                    } catch (putError) {
                        console.error('Error updating item:', item, putError);
                    }
                }
                await fetchRAID();
            }
        } catch (error) {
            console.error('Error deleting RAID Log item:', error);
        }
    };

    const handleColumnChange = useCallback((column) => {
        setColumns(prevColumns => {
            const currentColumns = prevColumns[activeTab];
            const updatedColumns = currentColumns.includes(column)
                ? currentColumns.filter(col => col !== column)
                : [...currentColumns, column];
            const newColumns = { ...prevColumns, [activeTab]: updatedColumns };
            saveProjectSettings(newColumns); // Save updated columns
            return newColumns;
        });
    }, [activeTab]);

    const handleDoubleClick = (rowId, column) => {
        setEditableCell({ rowId, column });
    };

    const handleBlur = () => {
        setEditableCell({ rowId: null, column: null });
    };

    const handleContextMenu = (event, raidId) => {
        event.preventDefault();
        setContextMenu({ visible: true, x: event.clientX, y: event.clientY, raidId });
    };

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate() + 1}`.padStart(2, '0');
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const calculateRiskScore = (impact, probability) => {
        return impact * probability;
    };

    const formatPercentage = (value) => {
        return `${Math.round(value * 100)}%`;
    };

    const formatComment = (comment) => {
        return comment.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    };

    const handleColumnVisibilityChange = (column) => {
        setVisibleColumns(prev => {
            const isVisible = prev[activeTab].includes(column);
            const updated = isVisible
                ? prev[activeTab].filter(col => col !== column)
                : [...prev[activeTab], column];
            return { ...prev, [activeTab]: updated };
        });
    };

    const filteredRAIDLog = raidlog.filter(item => item.raidType === activeTab.slice(0, -1));

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="container-fluid">
                <div className="row">
                    <main className="col-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">RAID Log</h1>
                            <div className="dropdown">
                                <button className="btn btn-primary raidlog-btn-primary" onClick={() => setIsPaneOpen(true)}>
                                    Columns
                                </button>
                            </div>
                        </div>
                        <div className="raidlog-tabs">
                            {['Risks', 'Actions', 'Issues', 'Decisions'].map(tab => (
                                <button
                                    key={tab}
                                    className={`raidlog-tab ${activeTab === tab ? 'active' : ''}`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                        {filteredRAIDLog.length === 0 ? (
                            <p>No {activeTab} Found</p>
                        ) : (
                            <div className="raidlog-table-responsive">
                                <table className="raidlog-table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            {(() => {
                                                const allColumns = [...new Set([...predefinedColumns[activeTab], ...columns[activeTab]])];
                                                return allColumns.map((col, index) => (
                                                    columns[activeTab].includes(col) && <th key={index}>{col}</th>
                                                ));
                                            })()}
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredRAIDLog.map((raidlogitem) => (
                                            <tr key={raidlogitem.raidId} onContextMenu={(e) => handleContextMenu(e, raidlogitem.raidId)}>
                                                <td>{raidlogitem.raidId}</td>
                                                {(() => {
                                                    const allColumns = [...new Set([...predefinedColumns[activeTab], ...columns[activeTab]])];
                                                    return allColumns.map((col, colIndex) => (
                                                        columns[activeTab].includes(col) && (
                                                            <td key={colIndex} onDoubleClick={() => handleDoubleClick(raidlogitem.raidId, col.toLowerCase())}>
                                                                {editableCell.rowId === raidlogitem.raidId && editableCell.column === col.toLowerCase() ? (
                                                                    col === 'Action' ? (
                                                                        <textarea
                                                                            name={col.toLowerCase()}
                                                                            value={raidlogitem[col.toLowerCase()] || ''}
                                                                            onChange={(e) => handleEditChange(raidlogitem.raidId, e)}
                                                                            onBlur={() => {
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            rows="3"
                                                                            style={{ width: '100%' }}
                                                                            autoFocus
                                                                        />
                                                                    ) : col === 'Status' || col === 'Priority' ? (
                                                                        <select
                                                                            name={col.toLowerCase()}
                                                                            value={raidlogitem[col.toLowerCase()] || ''}
                                                                            onChange={(e) => handleEditChange(raidlogitem.raidId, e)}
                                                                            onBlur={() => {
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            autoFocus
                                                                        >
                                                                            {activeTab === 'Actions' ? (
                                                                                <>
                                                                                    <option value="Not Started">Not Started</option>
                                                                                    <option value="In Progress">In Progress</option>
                                                                                    <option value="On Hold">On Hold</option>
                                                                                    <option value="Complete">Complete</option>
                                                                                </>
                                                                            ) : activeTab === 'Decisions' ? (
                                                                                <>
                                                                                    <option value="Open">Open</option>
                                                                                    <option value="Closed">Closed</option>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <option value="Open">Open</option>
                                                                                    <option value="Closed">Closed</option>
                                                                                </>
                                                                            )}
                                                                        </select>
                                                                    ) : col === 'Due' ? (
                                                                        <input
                                                                            type="date"
                                                                            name="due"
                                                                            value={raidlogitem.due ? new Date(raidlogitem.due).toISOString().split('T')[0] : ''}
                                                                            onChange={(e) => handleEditChange(raidlogitem.raidId, e)}
                                                                            onBlur={() => {
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            className="raidlog-input"
                                                                            autoFocus
                                                                        />
                                                                    ) : (
                                                                        <input
                                                                            type="text"
                                                                            name={col.toLowerCase()}
                                                                            value={raidlogitem[col.toLowerCase()] || ''}
                                                                            onChange={(e) => handleEditChange(raidlogitem.raidId, e)}
                                                                            onBlur={() => {
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            autoFocus
                                                                        />
                                                                    )
                                                                ) : (
                                                                    col === 'Due' ? (
                                                                        formatDate(raidlogitem.due)
                                                                    ) : (
                                                                        raidlogitem[col.toLowerCase()] || ''
                                                                    )
                                                                )}
                                                            </td>
                                                        )
                                                    ));
                                                })()}
                                                <td>
                                                    <button className="btn btn-danger" onClick={() => handleDelete(raidlogitem.raidId)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <button className="btn btn-success" type="button" onClick={handleAddRow}>Add Row</button>
                    </main>
                </div>
            </div>
            {contextMenu.visible && (
                <div
                    className="context-menu"
                    style={{ top: contextMenu.y, left: contextMenu.x }}
                >
                    <button onClick={() => handleDelete(contextMenu.raidId)}>Delete</button>
                </div>
            )}
            <SlidingPane
                isOpen={isPaneOpen}
                from="right"
                width="400px"
                title="Manage Columns"
                onRequestClose={() => setIsPaneOpen(false)}
            >
                <div className="stakeholder-register-pane-inner">
                    {(() => {
                        const allColumns = [...new Set([...predefinedColumns[activeTab], ...columns[activeTab]])];
                        return allColumns.map((col, index) => (
                            <div key={index} className="stakeholder-register-pane-item">
                                <input
                                    type="checkbox"
                                    checked={columns[activeTab].includes(col)}
                                    onChange={() => handleColumnChange(col)}
                                />
                                <label>{col}</label>
                            </div>
                        ));
                    })()}
                </div>
            </SlidingPane>
        </div>
    );
};

export default RAIDLog;