import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import './ComponentStyling/Register.css'; // Import the custom CSS

export default function Register() {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isOver13, setIsOver13] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const validatePassword = (password) => {
        const minLength = 12;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasPersonalInfo = [firstname, lastname, email].some(info => password.includes(info));
        return {
            minLength: password.length >= minLength,
            hasUpperCase,
            noPersonalInfo: !hasPersonalInfo
        };
    };

    const passwordValidation = validatePassword(password);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isOver13) {
            setMessage('You must be over the age of 13 to register.');
            return;
        }
        if (!passwordValidation.minLength || !passwordValidation.hasUpperCase || !passwordValidation.noPersonalInfo) {
            setMessage('Password must be at least 12 characters long, include at least one uppercase letter, and not contain personal information.');
            return;
        }
        if (password !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }
        setLoading(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/register`, { firstname, lastname, email, password });
            console.log(res);
            setMessage('Registration successful');
            navigate('/login');
        } catch (e) {
            console.error('Error:', e.response ? e.response.data : e.message);
            setMessage(e.response ? e.response.data.message : 'Server error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="App">
            <header className="siteHeader text-white text-center">
                <Navbar />
            </header>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="card-title text-center">Register</h1>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control" value={firstname} onChange={(e) => setFirstname(e.target.value)} disabled={loading} />
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control" value={lastname} onChange={(e) => setLastname(e.target.value)} disabled={loading} />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} disabled={loading} />
                                    </div>
                                    <div className="form-group">
                                        <label>Set Password</label>
                                        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} disabled={loading} />
                                        <ul className="password-rules">
                                            <li className={passwordValidation.minLength ? 'valid' : 'invalid'}>At least 12 characters</li>
                                            <li className={passwordValidation.hasUpperCase ? 'valid' : 'invalid'}>At least one uppercase letter</li>
                                            <li className={passwordValidation.noPersonalInfo ? 'valid' : 'invalid'}>Does not contain personal information</li>
                                        </ul>
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} disabled={loading} />
                                    </div>
                                    <div className="form-group form-check text-center">
                                        <input type="checkbox" id="ageCheck" checked={isOver13} onChange={(e) => setIsOver13(e.target.checked)} disabled={loading} />
                                        <label className="form-check-label" htmlFor="ageCheck">I confirm that I am over the age of 13</label>
                                    </div>
                                    <button type="submit" className="register-btn-primary btn-block" disabled={loading}>
                                        {loading ? 'Registering...' : 'Register'}
                                    </button>
                                </form>
                                <a href='/login' className="d-block text-center mt-3">Already have an account? Log In</a>
                                {message && <p className="text-center mt-3">{message}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};