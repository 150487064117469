import React from 'react';
import { Link, useParams } from 'react-router-dom';
import "./ComponentStyling/ProjectNavbar.css";

const ProjectNavbar = () => {
    const { projectId } = useParams();

    return (
        <nav className="col-12 bg-light sidebar-horizontal">
            <div className="position-sticky pt-3">
                <ul className="nav flex-row">
                    <li className="nav-item">
                        <Link className="nav-link active" to={`/projects/${projectId}`}>
                            Project Overview
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/stakeholders`}>
                            Stakeholders
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/raidlog`}>
                            RAID Log
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/kanban`}>
                            Kanban Board
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/settings`}>
                            Project Settings
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default ProjectNavbar;
