import React, { useState } from 'react';
import axios from 'axios';
import './PageCSS/RequestPasswordReset.css';
import Navbar from '../Components/Navbar.js';

export default function RequestPasswordReset() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/request-password-reset`, { email });
            setMessage('Password reset link sent to your email.');
        } catch (e) {
            setMessage('Error sending password reset link.');
        }
    };

    return (
        <div className = "App">
            <header className = "siteHeader text-white text-center">
                <Navbar />
            </header>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="card-title text-center">Request Password Reset</h1>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Enter your email"
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary reset-btn-primary btn-block">
                                        Send Reset Link
                                    </button>
                                </form>
                                {message && <p className="text-center mt-3">{message}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}