import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactGA from 'react-ga4';
import "./ComponentStyling/StakeholderMatrix.css";

const StakeholderMatrix = () => {
  const { projectId } = useParams();
  const [stakeholders, setStakeholders] = useState([]);
  const [showExportDropdown, setShowExportDropdown] = useState(false);

  useEffect(() => {
    const fetchStakeholders = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/stakeholders`);
        setStakeholders(response.data);
      } catch (e) {
        console.error('Error fetching stakeholders:', e);
      }
    };

    fetchStakeholders();
  }, [projectId]);

  useEffect(() => {
    ReactGA.initialize('G-SC3PE98CER');
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  const getMatrixCell = (power, interest) => {
    return stakeholders.filter(
      stakeholder => 
        stakeholder.power === power && 
        stakeholder.influence === interest
    );
  };

  const exportPDF = () => {
    const matrix = document.getElementById('stakeholder-matrix');
    const options = {
      scale: 3,
      useCORS: true,
      letterRendering: true,
      fontFaces: [
        {
          family: 'Arial',
          weight: 'normal'
        }
      ],
      removeContainer: true,
      backgroundColor: '#ffffff',
      logging: true,
      width: matrix.scrollWidth,
      height: matrix.scrollHeight,
      x: 0,
      y: 0,
      scrollX: 0,
      scrollY: 0,
      windowWidth: matrix.scrollWidth,
      windowHeight: matrix.scrollHeight
    };
    
    html2canvas(matrix, options).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF('landscape', 'mm', 'a4');
      
      // Get page dimensions
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      
      // Calculate image dimensions while maintaining aspect ratio
      const imgWidth = pageWidth * 0.8; // Use 80% of page width
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      // Calculate centering positions
      const x = (pageWidth - imgWidth) / 2;
      const y = (pageHeight - imgHeight) / 2;
      
      pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
      pdf.save('stakeholder-matrix.pdf');
    });
  };

  const exportPNG = () => {
    const matrix = document.getElementById('stakeholder-matrix');
    const options = {
      scale: 3,
      useCORS: true,
      letterRendering: true,
      backgroundColor: '#ffffff',
      logging: true,
      width: matrix.scrollWidth,
      height: matrix.scrollHeight
    };
    
    html2canvas(matrix, options).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'stakeholder-matrix.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    });
  };

  const MatrixCell = ({ power, interest }) => (
    <div className="matrix-cell">
      <div className="matrix-cell-content">
        {getMatrixCell(power, interest).map((stakeholder, index) => (
          <div
            key={stakeholder._id || index}
            className="matrix-stakeholder"
          >
            {stakeholder.name}
          </div>
        ))}
      </div>
    </div>
  );

  const HeaderCell = ({ children }) => (
    <div className="matrix-header">
      {children.split(' ').join('\u00A0')}
    </div>
  );

  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row">
          <main className="col-12 px-md-4">
            <div className="d-flex justify-content-end mb-3">
              <div className="export-dropdown-container">
                <div className="dropdown">
                  <button 
                    className="btn btn-primary dropdown-toggle" 
                    onClick={() => setShowExportDropdown(!showExportDropdown)}
                  >
                    Export
                  </button>
                  {showExportDropdown && (
                    <div className="dropdown-menu show">
                      <button className="dropdown-item" onClick={exportPDF}>
                        Export as PDF
                      </button>
                      <button className="dropdown-item" onClick={exportPNG}>
                        Export as PNG
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div id="stakeholder-matrix" className="stakeholder-matrix">
              <div className="matrix-row">
                <HeaderCell>Power / Interest</HeaderCell>
                <HeaderCell>Low Interest</HeaderCell>
                <HeaderCell>High Interest</HeaderCell>
              </div>
              <div className="matrix-row">
                <HeaderCell className="matrix-header-power">High Power</HeaderCell>
                <MatrixCell power="High" interest="Low" />
                <MatrixCell power="High" interest="High" />
              </div>
              <div className="matrix-row">
                <HeaderCell className="matrix-header-power">Low Power</HeaderCell>
                <MatrixCell power="Low" interest="Low" />
                <MatrixCell power="Low" interest="High" />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default StakeholderMatrix;