import { useEffect, useState } from 'react';
import "./PageCSS/Home.css";
import Navbar from '../Components/Navbar.js';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardImage from './Videos/Dashboard_Image.png';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

// Move static data outside the component
const features = [
    {
        title: "Customizability",
        description: "Tailor the software to fit your unique needs. Customize dashboards, workflows, and settings to create a personalized project management experience."
    },
    {
        title: "RAID Logs",
        description: "Easily track Risks, Assumptions, Issues, and Dependencies with our intuitive RAID logs. Keep your projects on track by managing potential obstacles effectively."
    },
    {
        title: "Stakeholder Registers",
        description: "Maintain a comprehensive list of all stakeholders, their roles, and their interests. Ensure clear communication and engagement throughout the project lifecycle."
    },
    {
        title: "Daily Planners",
        description: "Organize your day with our integrated daily planners. Schedule tasks, set priorities, and stay focused on what matters most."
    },
    {
        title: "Project Planning",
        description: "Plan and visualize your projects from start to finish. Map out timelines, allocate resources, and track progress effectively."
    },
    {
        title: "Reporting",
        description: "Generate comprehensive reports and insights to track project performance, resource utilization, and key metrics for informed decision-making."
    }
];

const benefits = [
    {
        title: "Streamlined Project Management",
        description: "Our software simplifies project management by providing all the tools you need in one place."
    },
    {
        title: "Increased Productivity",
        description: "Boost your productivity with our intuitive interface and powerful features."
    },
    {
        title: "Comprehensive Tracking",
        description: "Keep track of all your project details, deadlines, and progress in one place."
    },
    {
        title: "Customizable Experience",
        description: "Tailor the platform to fit your unique workflow and requirements."
    }
];

export default function Home() {
    const navigate = useNavigate();
    const [isYearly, setIsYearly] = useState(false);

    // Animation variants
    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: { duration: 0.6, ease: "easeOut" }
        }
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { staggerChildren: 0.2 }
        }
    };

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    return (
        <div className="App">
            <Helmet>
                <title>PMHub.io - Project Management Simplified</title>
                <meta 
                    name="description" 
                    content="PMHub.io offers streamlined project management tools, including RAID logs, stakeholder registers, daily planners, and comprehensive reporting to boost your team's productivity." 
                />
                <meta 
                    name="keywords" 
                    content="Project Management, RAID Logs, Stakeholder Registers, Daily Planners, Reporting, Productivity, PMHub" 
                />
            </Helmet>
            <Navbar />
            <main>
                <div className="main-content-wrapper">
                    <section className="hero">
                        <div className="container">
                            {/* Hero Section */}
                            <div className="row align-items-center">
                                <motion.div 
                                    className="col-lg-6"
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    variants={fadeInUp}
                                >
                                    <motion.h1 
                                        className="hero-title"
                                        animate={{ scale: [1, 1.02, 1] }}
                                        transition={{ duration: 2, repeat: Infinity }}
                                    >
                                        Project management, simplified
                                    </motion.h1>
                                    <p className="hero-subtitle">Plan projects, track artifacts, and manage information all in one hub</p>
                                    <motion.button 
                                        className="home-btn-primary btn-lg"
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={() => navigate('/register')}
                                    >
                                        Get Started
                                    </motion.button>
                                </motion.div>
                                <motion.div 
                                    className="col-lg-6"
                                    initial={{ opacity: 0, x: 50 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    viewport={{ once: true }}
                                >
                                    <img src={DashboardImage} className="hero-image" alt="Dashboard preview" />
                                </motion.div>
                            </div>
                        </div>
                    </section>
                    
                    <motion.section 
                        className="benefits"
                        variants={containerVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <div className="container">
                            <motion.h2 variants={fadeInUp}>Why Choose Us?</motion.h2>
                            <div className="benefit-grid">
                                {benefits.map((benefit, index) => (
                                    <motion.div 
                                        key={index}
                                        className="benefit-card"
                                        variants={fadeInUp}
                                        transition={{ delay: index * 0.1 }}
                                    >
                                        <h3>{benefit.title}</h3>
                                        <p>{benefit.description}</p>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </motion.section>

                    {/* Features Section */}
                    <motion.section 
                        className="features"
                        variants={containerVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <div className="container">
                            <motion.div 
                                className="section-header"
                                variants={fadeInUp}
                            >
                                <h2>A Suite of Features</h2>
                                <p className="lead">Tailored just for you</p>
                            </motion.div>
                            <div className="row g-4">
                                {features.map((feature, index) => (
                                    <motion.div 
                                        key={index}
                                        className="col-lg-4 col-md-6"
                                        variants={fadeInUp}
                                        transition={{ delay: index * 0.1 }}
                                    >
                                        <div className="feature-card">
                                            <h3>{feature.title}</h3>
                                            <p>{feature.description}</p>
                                        </div>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </motion.section>
                </div>

                {/* Pricing Section */}
                <motion.section 
                    className="pricing"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    <div className="container">
                        <motion.h2 
                            className="shiny-text text-center mb-5"
                            variants={fadeInUp}
                        >
                            Pricing Plans
                        </motion.h2>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="pricingToggle"
                                checked={isYearly}
                                onChange={() => setIsYearly(!isYearly)}
                            />
                            <label className="form-check-label pricingPlanLabel" htmlFor="pricingToggle">
                                {isYearly ? 'Yearly' : 'Monthly'}
                            </label>
                        </div>
                        <div className="row">
                            <motion.div 
                                className="col-lg-6 col-md-6"
                                variants={fadeInUp}
                            >
                                <div className="pricing-card">
                                    <h3>Free Forever</h3>
                                    <p className="price">Free</p>
                                    <ul>
                                        <li>3 Total Projects</li>
                                        <li>RAID Log</li>
                                        <li>Stakeholder Register</li>
                                        <li>Daily Journal</li>
                                        <li>Limited Dashboard Reporting</li>
                                    </ul>
                                    <motion.button 
                                        className="home-btn-primary"
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        Choose Plan
                                    </motion.button>
                                </div>
                            </motion.div>
                            <motion.div 
                                className="col-lg-6 col-md-6"
                                variants={fadeInUp}
                            >
                                <div className="pricing-card">
                                    <h3>Professional</h3>
                                    <p className="price">{isYearly ? '$29.99/year' : '$2.99/month'}</p>
                                    <ul>
                                        <li>Unlimited Projects</li>
                                        <li>RAID Log</li>
                                        <li>Stakeholder Register</li>
                                        <li>Daily Journal</li>
                                        <li>Full Dashboard Reporting</li>
                                    </ul>
                                    <motion.button 
                                        className="home-btn-primary"
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        Choose Plan
                                    </motion.button>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </motion.section>

                {/* About Us Section */}
                <motion.section 
                    className="aboutUs"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    <div className="container">
                        <div className="row">
                            <motion.div 
                                className="col-lg-4"
                                variants={fadeInUp}
                            >
                                <h1 className="shiny-text">About Us</h1>
                            </motion.div>
                            <motion.div 
                                className="col-lg-8"
                                variants={fadeInUp}
                            >
                                <p>Welcome to Project Manager Hub, where we simplify project management for teams of all sizes. Our mission is to provide a user-friendly platform that helps you plan, track, and manage your projects efficiently.</p>
                                <p>Founded in 2024, PMHub was created to streamline project management and foster seamless collaboration. Our core values — innovation, collaboration, and customer satisfaction — drive everything we do.</p>
                                <p>We embrace agile methodology, continuously improving our platform to meet the evolving needs of our users. Our dedicated team is passionate about delivering the best experience and helping you succeed.</p>
                                <p>Join us on our journey to make project management effortless and effective. Together, we can achieve great things.</p>
                            </motion.div>
                        </div>
                    </div>
                </motion.section>
            </main>
        </div>
    );
}