import React, { useEffect, useState } from 'react';
import { useAuth } from "../Components/AuthContext.js"; 
import NavbarAuth from '../Components/Navbar_Authenticated';
import axiosInstance from '../axiosConfig'; // Ensure axiosInstance is used

const AccountPage = () => {
    const { user, isAuthenticated } = useAuth();
    const [userProfile, setUserProfile] = useState(null);
    
    useEffect(() => {
        let isMounted = true;

        const fetchUser = async (userId) => {
            try {
                console.log('Fetching user information for user ID:', userId);
                const response = await axiosInstance.get(`/api/user/${userId}`, { withCredentials: true });
                console.log('User information fetched:', response.data);
                if (isMounted) {
                    setUserProfile(response.data);
                }
            } catch (e) {
                console.error('Error fetching user data:', e);
                if (e.response) {
                    console.error('Response status:', e.response.status);
                    console.error('Response data:', e.response.data);
                }
            }
        };

        if (isAuthenticated && user) {
            console.log('User from context:', user);
            const userId = user.user_id;

            fetchUser(userId);
        } else {
            console.log('User is not authenticated or not available');
        }

        return () => {
            console.log('AccountPage component unmounted');
            isMounted = false;
        };

    }, [isAuthenticated, user]);

    useEffect(() => {
        if (!isAuthenticated) {
            console.error('User is not authenticated');
        }
        if (!user) {
            console.error('User data is not available in context');
        }
        if (userProfile) {
            console.log('User profile data:', userProfile);
        }
    }, [isAuthenticated, user, userProfile]);

    return (
        <div>
            <NavbarAuth />
            <h1>Account Information</h1>
            {userProfile ? (
                <>
                    <p><strong>Name:</strong> {userProfile.firstname} {userProfile.lastname}</p>
                    <p><strong>Email:</strong> {userProfile.email}</p>
                    <p><strong>User ID:</strong> {user.user_id}</p>
                </>
            ) : (
                <p>Loading user profile...</p>
            )}
        </div>
    );
};

export default AccountPage;