import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import "./ComponentStyling/MeetingPage.css";
import ProjectNavbar from './ProjectNavbar';
import ReactGA from 'react-ga4';

console.log('Meeting page loaded');

const MeetingPage = () => {
    const { projectId, meetingId } = useParams();
    const navigate = useNavigate();
    const [meetingTitle, setMeetingTitle] = useState('Meeting 1');
    const [meetingMinutes, setMeetingMinutes] = useState('');
    const [meetingDate, setMeetingDate] = useState('');
    const [meetingTime, setMeetingTime] = useState('');
    const [quillMinutes, setQuillMinutes] = useState(null);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [isEditingTime, setIsEditingTime] = useState(false);
    const [originalTitle, setOriginalTitle] = useState('');
    const [originalDate, setOriginalDate] = useState('');
    const [originalTime, setOriginalTime] = useState('');

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    useEffect(() => {
        console.log('MeetingPage component rendered');
        if (!quillMinutes) {
            console.log('Initializing Quill editor');
            const quillInstance = new Quill('#editor-minutes', {
                theme: 'snow',
                modules: {
                    toolbar: [
                        [{ 'font': [] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'align': [] }],
                        ['clean']
                    ]
                }
            });
            quillInstance.on('text-change', () => {
                setMeetingMinutes(quillInstance.root.innerHTML);
            });
            quillInstance.root.style.fontSize = '16px'; // Set default font size
            setQuillMinutes(quillInstance);
        }
    }, [quillMinutes]);

    useEffect(() => {
        if (quillMinutes) {
            fetchMeetingDetails();
        }
    }, [quillMinutes, meetingId]);

    useEffect(() => {
        const autoSaveInterval = setInterval(() => {
            handleAutoSave();
        }, 5000); // Auto-save every 5 seconds

        return () => {
            clearInterval(autoSaveInterval);
        };
    }, [meetingMinutes]);

    const fetchMeetingDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/meeting-details/${meetingId}`);
            const { meetingTitle, meetingNotes, date, time } = response.data;
            setMeetingTitle(meetingTitle);
            setMeetingMinutes(meetingNotes);
            const defaultDate = new Date().toISOString().split('T')[0]; // Ensure default date is in YYYY-MM-DD format
            setMeetingDate(date || defaultDate); // Set default date if undefined
            const defaultTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            setMeetingTime(time || defaultTime); // Set default time if undefined
            if (quillMinutes) {
                quillMinutes.root.innerHTML = meetingNotes;
            }
        } catch (error) {
            console.error('Error fetching meeting details:', error);
            console.error('Response data:', error.response?.data);
        }
    };

    const handleAutoSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/meetings/${meetingId}/minutes`, { meetingMinutes });
            console.log('Auto-saved meeting minutes');
        } catch (error) {
            console.error('Error auto-saving meeting minutes:', error);
        }
    };

    const handleBlur = async (field) => {
        try {
            if (field === 'title') {
                if (meetingTitle !== originalTitle) {
                    await axios.put(`${process.env.REACT_APP_API_URL}/api/meetings/${meetingId}/title`, { meetingTitle });
                }
                setIsEditingTitle(false);
            } else if (field === 'date') {
                if (meetingDate !== originalDate) {
                    await axios.put(`${process.env.REACT_APP_API_URL}/api/meetings/${meetingId}/date`, { date: meetingDate });
                }
                setIsEditingDate(false);
            } else if (field === 'time') {
                if (meetingTime !== originalTime) {
                    await axios.put(`${process.env.REACT_APP_API_URL}/api/meetings/${meetingId}/time`, { time: meetingTime });
                }
                setIsEditingTime(false);
            }
        } catch (error) {
            console.error(`Error updating meeting ${field}:`, error);
        }
    };

    const handleDoubleClick = (field) => {
        if (field === 'title') {
            setOriginalTitle(meetingTitle);
            setIsEditingTitle(true);
        } else if (field === 'date') {
            setOriginalDate(meetingDate);
            setIsEditingDate(true);
        } else if (field === 'time') {
            setOriginalTime(meetingTime);
            setIsEditingTime(true);
        }
    };

    const handleDeleteMeeting = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/meetings/${meetingId}`);
            navigate(`/projects/${projectId}`);
        } catch (error) {
            console.error('Error deleting meeting:', error);
        }
    };

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate() + 1}`.padStart(2, '0');
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const formatTime = (timeString) => {
        if (!timeString) return '';
        const [hour, minute] = timeString.split(':');
        const hourInt = parseInt(hour, 10);
        const ampm = hourInt >= 12 ? 'PM' : 'AM';
        const formattedHour = hourInt % 12 || 12;
        return `${formattedHour}:${minute} ${ampm}`;
    };

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <main className="main-content">
                <div className="flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom align-left">
                    {isEditingTitle ? (
                        <input
                            type="text"
                            className="form-control meeting-title-input"
                            value={meetingTitle}
                            onChange={(e) => setMeetingTitle(e.target.value)}
                            onBlur={() => handleBlur('title')}
                            autoFocus
                        />
                    ) : (
                        <h1 className="meeting-title" onDoubleClick={() => handleDoubleClick('title')}>{meetingTitle}</h1>
                    )}
                    <div className="meeting-details">
                        {isEditingDate ? (
                            <input
                                type="date"
                                className="form-control meeting-date"
                                value={meetingDate}
                                onChange={(e) => setMeetingDate(e.target.value)}
                                onBlur={() => handleBlur('date')}
                                autoFocus
                            />
                        ) : (
                            <span onDoubleClick={() => handleDoubleClick('date')}>Date: {formatDate(meetingDate)}</span>
                        )}
                        {isEditingTime ? (
                            <input
                                type="time"
                                className="form-control meeting-time"
                                value={meetingTime}
                                onChange={(e) => setMeetingTime(e.target.value)}
                                onBlur={() => handleBlur('time')}
                                autoFocus
                            />
                        ) : (
                            <span onDoubleClick={() => handleDoubleClick('time')}>Time: {formatTime(meetingTime)}</span>
                        )}
                        <button className="btn btn-danger delete-meeting" onClick={handleDeleteMeeting}>Delete Meeting</button>
                    </div>
                </div>
                <div className="row min-vh-100">
                    <div className="col-md-12">
                        <div id="editor-minutes" />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default MeetingPage;