import "./ComponentStyling/Navbar.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useAuth } from "./AuthContext";

export default function NavbarAuthenticated() {
    const { user, isAuthenticated, logout } = useAuth();

    return (
        <header className="navbar-container">
            <div className="navbar">
                <Link to="/" className="navbar-logo">PMHub.io</Link>
                <ul className="navbar-right">
                    <CustomLink to="/dashboard">Dashboard</CustomLink>
                    <CustomLink to={`/${user.user_id}/dailyJournal`}>Daily Journal</CustomLink>
                    <CustomLink to="/account">Account</CustomLink>
                    <CustomLink to="/login" onClick={logout}>Log Out</CustomLink>
                </ul>
            </div>
        </header>
    );
}

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
                {isActive && <span className="active-indicator"></span>}
            </Link>
        </li>
    );
}